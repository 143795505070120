import React from 'react';
import './Footer.css';

function Footer() {
    return (
        <>
        <footer>
          <div className="footer">
            <p>Visit our GitHub: https://github.com/NasierF/QuizWiz</p>
          </div>
        </footer>
        </>
    );
}

export default Footer;