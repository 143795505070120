import React from 'react';
import './FlashCards.css';

function FlashCards(){
    return (
        <>
            <div className="note-to-judges">
              <h1>Note To Judges:</h1>
              <h1>Due to the complexity of writing a program on a machine that is not open source, we ran into time constraints when completing this part of the project.</h1>
              <h1>This page was intended to be a user interface where users could make study sets to easily transfer them to their calculator application.</h1>
              <h1>Hopefully, sometime in the future, we will get this feature up and running, and our calculator application will be easily accessible to anyone from any tech experience level.</h1>
            </div>
        </>
      );

}

export default FlashCards;